<script lang="ts" setup>
import * as d3 from 'd3';
import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
import { ref, onMounted, onUnmounted, nextTick, watch } from 'vue';
import { useElementBounding } from '@vueuse/core';
import { theme } from '#tailwind-config';

const { formatNumberToIsoNumber } = useNumbers();

// Types
export interface SankeyNode {
  name: string;
  value?: number;
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
  id?: string | number;
}

export interface AreaWithConsumptionSankey {
  id: number;
  name: string;
  consumption: number;
  parent_id: number | null;
  drift?: number;
  top_level_percentage?: number;
  parent_percentage?: number;
}

// Props et Emits
const props = withDefaults(
  defineProps<{
    loading?: boolean;
    areaWithConsumption: AreaWithConsumptionSankey[];
  }>(),
  {
    loading: false,
    areaWithConsumption: () => [],
  },
);
const emit = defineEmits<{
  'node-click': [node: AreaWithConsumptionSankey];
}>();

// Data
const container = ref<HTMLDivElement | null>(null);
const tooltip = ref<HTMLDivElement | null>(null);
const width = ref(0);
const height = ref(0);

// État du tooltip
const tooltipState = ref({
  show: false,
  x: 0,
  y: 0,
  content: {
    title: '',
    subtitle: '',
    total: '',
    parent: '',
    name: '',
    id: null,
  },
});

// Utiliser VueUse pour les dimensions
const { x: containerX, y: containerY, update: updateContainer } = useElementBounding(container);

// Methods
const updateDimensions = () => {
  if (!container.value) return;
  const rect = container.value.getBoundingClientRect();
  width.value = rect.width || 1200;
  height.value = rect.height || 400;
};

const createChart = () => {
  if (!container.value || !props.areaWithConsumption?.length || props.loading) return;

  updateDimensions();

  // Supprimer les tooltips existants
  d3.select(container.value).selectAll('.chart-tooltip').remove();
  d3.select(container.value).selectAll('svg').remove();

  const svg = d3
    .select(container.value)
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('viewBox', `0 0 ${width.value} ${height.value}`)
    .attr('preserveAspectRatio', 'xMinYMin')
    .style('background-color', '#ffffff');

  const defs = svg.append('defs');
  const pattern = defs.append('pattern').attr('id', 'dots').attr('width', 20).attr('height', 20).attr('patternUnits', 'userSpaceOnUse');
  pattern.append('circle').attr('cx', 3).attr('cy', 3).attr('r', 1).attr('fill', '#e5e7eb');
  svg.append('rect').attr('width', width.value).attr('height', height.value).attr('fill', 'url(#dots)');

  const nodes = props.areaWithConsumption.map((area) => ({
    name: area.name,
    originalValue: area.consumption,
    value: area.consumption,
    id: area.id,
  }));

  // Calculer la somme totale des consommations finales
  const totalConsumption = props.areaWithConsumption
    .filter((area) => !props.areaWithConsumption.some((a) => a.parent_id === area.id))
    .reduce((sum, area) => sum + area.consumption, 0);

  // Mettre à jour la valeur du nœud racine (Production)
  const rootNode = nodes.find((node) => !props.areaWithConsumption.find((area) => area.id === node.id)?.parent_id);
  if (rootNode) {
    rootNode.value = totalConsumption;
  }

  const adjustNodeValues = () => {
    const nodesByLevel = new Map();
    nodes.forEach((node) => {
      const level =
        props.areaWithConsumption.find((a) => a.id === node.id)?.parent_id === null
          ? 0
          : props.areaWithConsumption.find((a) => a.id === props.areaWithConsumption.find((a) => a.id === node.id)?.parent_id)
                ?.parent_id === null
            ? 1
            : 2;
      if (!nodesByLevel.has(level)) nodesByLevel.set(level, []);
      nodesByLevel.get(level).push(node);
    });

    // Ajuster les valeurs des nœuds intermédiaires
    for (let level = 1; level <= Math.max(...nodesByLevel.keys()); level++) {
      const nodesInLevel = nodesByLevel.get(level) || [];
      nodesInLevel.forEach((node) => {
        const childrenIds = props.areaWithConsumption.filter((area) => area.parent_id === node.id).map((area) => area.id);

        if (childrenIds.length > 0) {
          const childrenSum = childrenIds.reduce((sum, childId) => {
            const childArea = props.areaWithConsumption.find((a) => a.id === childId);
            return sum + (childArea?.consumption || 0);
          }, 0);
          node.value = childrenSum;
        }
      });
    }
  };

  adjustNodeValues();

  // Doubler la valeur des nœuds de la colonne du milieu
  nodes.forEach((node) => {
    const level =
      props.areaWithConsumption.find((a) => a.id === node.id)?.parent_id === null
        ? 0
        : props.areaWithConsumption.find((a) => a.id === props.areaWithConsumption.find((a) => a.id === node.id)?.parent_id)?.parent_id ===
            null
          ? 1
          : 2;

    if (level === 1) {
      node.value = node.value * 2;
    }
  });

  const links = props.areaWithConsumption
    .filter((area) => area.parent_id !== null)
    .map((area) => {
      const sourceNode = nodes.find((n) => n.id === area.parent_id);
      const targetNode = nodes.find((n) => n.id === area.id);
      return {
        source: props.areaWithConsumption.findIndex((a) => a.id === area.parent_id),
        target: props.areaWithConsumption.findIndex((a) => a.id === area.id),
        value: area.consumption,
        sourceNode,
        targetNode,
      };
    })
    .filter((link) => link.source !== -1 && link.target !== -1);

  // Définir des marges fixes pour maximiser l'espace utilisé
  const rightMargin = 164 + 32;
  const leftMargin = 32;

  // Ajuster la valeur du nœud principal si des nœuds sont trop petits
  const mainNode = nodes.find((node) => !props.areaWithConsumption.find((area) => area.id === node.id)?.parent_id);
  if (mainNode) {
    let additionalValue = 0;
    nodes.forEach((node) => {
      if (node !== mainNode && node.value < 30) {
        additionalValue += 30 - node.value;
      }
    });
    mainNode.value += additionalValue;
  }

  // Mettre à jour la hauteur du SVG
  svg.attr('height', height.value);
  svg.select('rect').attr('height', height.value);

  const sankeyGenerator = sankey<SankeyNode, any>()
    .nodeWidth(40)
    .nodePadding(24)
    .extent([
      [leftMargin, 32],
      [width.value - rightMargin, height.value - 32],
    ])
    .nodeSort(null)
    .nodeAlign((node) => node.depth);

  const graph = sankeyGenerator({
    nodes: nodes.map((d) => Object.assign({}, d)),
    links: links.map((d) => Object.assign({}, d)),
  });

  // Ajuster la position horizontale des nœuds après leur génération
  const availableWidth = width.value - rightMargin - leftMargin;

  // Calculer le nombre de niveaux uniques
  const uniqueLevels = new Set(graph.nodes.map((node) => node.depth)).size;

  // Calculer les positions x pour répartir uniformément les nœuds sur toute la largeur
  graph.nodes.forEach((node) => {
    if (graph.nodes.length === 1) {
      // Si un seul nœud, le centrer horizontalement
      node.x0 = leftMargin + (availableWidth - 40) * 0.5;
      node.x1 = node.x0 + 40;
      // Forcer la position verticale
      const nodeHeight = 60;
      node.y0 = (height.value - nodeHeight) / 2;
      node.y1 = node.y0 + nodeHeight;
    } else {
      // Répartir uniformément les nœuds sur la largeur disponible
      const nodeWidth = 40;
      const usableWidth = availableWidth - nodeWidth;
      const step = usableWidth / (uniqueLevels - 1);

      node.x0 = leftMargin + step * node.depth;
      node.x1 = node.x0 + nodeWidth;
    }
  });

  svg
    .append('g')
    .selectAll('path')
    .data(graph.links)
    .join('path')
    .attr('d', sankeyLinkHorizontal())
    .attr('stroke', () => theme.colors.green[300])
    .attr('stroke-width', (d) => Math.max(1, d.width))
    .attr('stroke-opacity', 0.4)
    .attr('fill', 'none');

  svg
    .append('g')
    .selectAll('rect')
    .data(graph.nodes)
    .join('rect')
    .attr('x', (d) => d.x0)
    .attr('y', (d) => d.y0)
    .attr('height', (d) => d.y1 - d.y0)
    .attr('width', (d) => d.x1 - d.x0)
    .attr('fill', () => theme.colors.green[400])
    .attr('stroke', () => theme.colors.green[600])
    .attr('stroke-width', 1)
    .attr('rx', 4)
    .attr('ry', 4)
    .style('transition', 'fill 0.1s ease-in-out')
    .on('mouseover', function (event, d) {
      d3.select(this).attr('fill', () => theme.colors.green[500]);

      const area = props.areaWithConsumption.find((a) => a.id === d.id);

      if (!area || !tooltip.value) return;

      updateContainer();

      // Utiliser les pourcentages fournis dans les props avec valeurs par défaut
      const totalPercentage = formatNumberToIsoNumber(area.top_level_percentage, 2);

      const parentPercentage = formatNumberToIsoNumber(area.parent_percentage, 2);

      // Mettre à jour le contenu du tooltip
      tooltipState.value.content = {
        title: 'Consommation',
        subtitle: `${formatNumberToIsoNumber(area.consumption)} kWh`,
        total: `${totalPercentage}%`,
        parent: `${parentPercentage}%`,
        name: area.name,
        id: area.id,
      };

      // Calculer la position du tooltip
      const element = event.target as SVGRectElement;
      const rect = element.getBoundingClientRect();
      const tooltipWidth = 178;

      let tooltipX = rect.left - containerX.value + rect.width + 10;

      // Si le tooltip dépasse à droite, le placer à gauche
      if (tooltipX + tooltipWidth > width.value) {
        tooltipX = rect.left - containerX.value - tooltipWidth - 10;
      }

      // Mettre à jour la position du tooltip
      tooltipState.value.x = tooltipX;
      tooltipState.value.y = rect.top - containerY.value;
      tooltipState.value.show = true;
    })
    .on('mouseout', function () {
      d3.select(this).attr('fill', () => theme.colors.green[400]);
      tooltipState.value.show = false;
    })
    .on('click', (event, d) => {
      const area = props.areaWithConsumption.find((a) => a.id === d.id);
      if (area) {
        emit('node-click', area);
      }
    });

  svg
    .append('g')
    .selectAll('text')
    .data(graph.nodes)
    .join('text')
    .attr('x', (d) => d.x1 + 8)
    .attr('y', (d) => (d.y1 + d.y0) / 2)
    .attr('dy', '0.35em')
    .attr('text-anchor', 'start')
    .attr('fill', theme.colors.gray[700])
    .style('font-size', '14px')
    .style('font-weight', '500')
    .text((d) => (d.name.length > 12 ? d.name.substring(0, 12) + '...' : d.name));

  svg
    .append('g')
    .selectAll('g')
    .data(graph.nodes)
    .join('g')
    .each(function (d) {
      const g = d3.select(this);
      const originalArea = props.areaWithConsumption.find((area) => area.id === d.id);
      let x, bbox;

      // Ne pas afficher le pourcentage pour le premier nœud
      const isFirstNode = props.areaWithConsumption.indexOf(originalArea) === 0;
      if (!isFirstNode) {
        const percentage = formatNumberToIsoNumber(originalArea.top_level_percentage, 2);

        // Utiliser une distance fixe de 116px au lieu de calculer en fonction de la largeur maximale
        x = d.x1 + 128;
        const y = (d.y1 + d.y0) / 2;

        const text = g
          .append('text')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', '0.35em')
          .attr('text-anchor', 'start')
          .text(`${percentage}%`)
          .style('font-size', '12px');

        bbox = text.node().getBBox();
        g.insert('rect', 'text')
          .attr('x', bbox.x - 6)
          .attr('y', bbox.y - 2)
          .attr('width', bbox.width + 12)
          .attr('height', bbox.height + 4)
          .attr('rx', 4)
          .attr('fill', theme.colors.blue[50]);

        text.attr('fill', theme.colors.blue[600]);
      }

      // Si il y a un drift, l'ajouter après le pourcentage
      if (originalArea?.drift) {
        if (!x) {
          // Utiliser une distance fixe de 116px au lieu de calculer en fonction de la largeur maximale
          x = d.x1 + 128;
        }
        const y = (d.y1 + d.y0) / 2;

        const driftText = g
          .append('text')
          .attr('x', x + (bbox ? bbox.width + 16 : 0))
          .attr('y', y)
          .attr('dy', '0.35em')
          .attr('text-anchor', 'start')
          .text(`+ ${originalArea.drift} %`)
          .style('font-size', '12px');

        const driftBbox = driftText.node().getBBox();
        g.insert('rect', 'text')
          .attr('x', driftBbox.x - 4)
          .attr('y', driftBbox.y - 2)
          .attr('width', driftBbox.width + 8)
          .attr('height', driftBbox.height + 4)
          .attr('rx', 4)
          .attr('fill', '#FEF2F2');

        driftText.attr('fill', '#B91C1C');
      }
    });
};

const resizeObserver = new ResizeObserver(() => {
  updateDimensions();
  createChart();
});

// Watch areaWithConsumption changes
watch(
  () => props.areaWithConsumption,
  () => {
    nextTick(() => {
      createChart();
    });
  },
  { immediate: true, deep: true },
);

// Cleanup function
const cleanup = () => {
  if (container.value) {
    // Remove all SVG elements and tooltips
    d3.select(container.value).selectAll('svg').remove();
    d3.select(container.value).selectAll('.chart-tooltip').remove();

    // Reset tooltip state
    tooltipState.value = {
      show: false,
      x: 0,
      y: 0,
      content: {
        title: '',
        subtitle: '',
        total: '',
        parent: '',
        name: '',
        id: null,
      },
    };
  }
  resizeObserver.disconnect();
};

onMounted(() => {
  if (container.value) {
    resizeObserver.observe(container.value);
    createChart();
  }
});

onUnmounted(() => {
  cleanup();
});
</script>

<template>
  <div ref="container" class="w-full relative h-[70vh] min-h-[300px]">
    <div v-if="loading" class="flex absolute top-0 left-0 justify-center items-center h-full w-full bg-opacity-50 bg-gray-100">
      <ui-loader />
    </div>
    <div
      v-else-if="areaWithConsumption?.length === 0"
      class="h-full z-10 flex flex-col items-center rounded justify-center bg-opacity-50 bg-gray-100"
    >
      <slot name="no-data">
        <p class="text-gray-500 text-sm">{{ $t('global.no_data') }}</p>
      </slot>
    </div>
    <!-- Tooltip -->
    <div ref="tooltip">
      <Transition
        enter-active-class="transition-all duration-200 ease-out"
        leave-active-class="transition-all duration-200 ease-out"
        enter-from-class="opacity-0 -translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-2"
      >
        <div
          v-if="tooltipState.show"
          class="chart-tooltip absolute z-[9999] pointer-events-none"
          :style="{ left: `${tooltipState.x}px`, top: `${tooltipState.y}px` }"
        >
          <div class="bg-gray-800 p-2 rounded-lg shadow-lg w-[178px]">
            <!-- Title Section -->
            <div class="mb-1">
              <div class="text-gray-200 text-xs">{{ tooltipState.content.title }}</div>
              <div class="text-lg text-white font-semibold">{{ tooltipState.content.subtitle }}</div>
            </div>

            <!-- Body Section -->
            <div
              v-if="tooltipState.content.id !== areaWithConsumption[0].id"
              class="space-y-2 text-xs border-t border-b border-gray-700 py-2"
            >
              <div class="flex justify-between">
                <span class="text-gray-400">{{ $t('global.pourcent_of_total_consumption') }}</span>
                <span class="text-white font-semibold">{{ tooltipState.content.total }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-400">{{ $t('global.pourcent_of_parent') }}</span>
                <span class="text-white font-semibold">{{ tooltipState.content.parent }}</span>
              </div>
            </div>

            <!-- Footer Section -->
            <div class="py-2 pb-1 flex items-center justify-center text-gray-400 text-[10px] text-center">
              {{ tooltipState.content.name }}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style scoped>
.chart-tooltip {
  transform-origin: top center;
}
</style>
